.colleagues-review-template {
  .review-template {
    overflow: hidden auto;
    height: 100%;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .typography-title-body {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .typography-paragraph {
    font-size: 0.875rem;
  }

  .colleagues-review-input {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .colleagues-review-input:focus {
    box-shadow: none !important;
  }

  .colleagues-review-input.dark:focus {
    background: #f0eff9;
    color: #402a87;
  }

  .colleagues-review-template-button {
    display: flex;
    justify-content: center;
    margin-top: 21px;
  }

  .colleagues-review-input,
  .custom-slider,
  .custom-radio,
  .custom-checkbox {
    pointer-events: none;
  }

  .custom-radio.light {
    .ant-radio-wrapper.ant-radio-wrapper-disabled {
      .ant-radio {
        .ant-radio-inner {
          border-color: rgba(64, 42, 135, 0.6);
          &:after {
            background-color: #402a87;
          }
        }
      }
    }
  }

  .custom-checkbox.light {
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      span {
        color: rgba(64, 42, 135, 0.45);
      }
    }
  }

  .custom-checkbox {
    label {
      display: flex;
      align-items: center;
    }
  }

  .custom-slider.dark.ant-slider-disabled {
    .ant-slider-step {
      .ant-slider-dot {
        border-color: #fff;
        background-color: #4a495c;
      }
    }
  }

  .custom-radio.dark {
    .ant-radio-wrapper.ant-radio-wrapper-disabled {
      span {
        color: rgba(189, 167, 232, 0.45);
      }
    }
  }

  .custom-checkbox.dark {
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      span {
        color: rgba(189, 167, 232, 0.45);
      }
    }
  }

  .edit-btn,
  .remove-btn{
    display: flex;
    align-items: center;
    padding: 11px;
  }

  .arrow-btn {
    background: none;
    border: none;
    display: flex;
    box-shadow: none;
    align-items: center;
    padding: 0;
  }

  .template-change {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;
    gap: 0.5em;
  }

  .splide__arrow {
    color: rgba(64, 42, 135, 0.45);
    cursor: pointer;

    &:is(:hover, :active, :focus) {
      color: #402a87;
    }

    &.dark {
      color: #bda7e8;
    }

    &.dark:hover {
      color: white;
    }

    &.disabled {
      color: rgba(64, 42, 135, 0.40);
    }
  }

  .splide__arrow.dark.disabled {
    color: #bda7e8;
  }

  .notes {
    background-color: rgba(189, 167, 232, 0.6) !important;
    padding: 0.5rem;
    border-radius: 14px;

    .show,
    .hide {
      align-items: center;
      font-size: 0.875rem;
      cursor: pointer;
      .arrow {
        color: #bda7e8;
      }

      &:hover {
        text-decoration: underline;

        .arrow {
          color: #402a87;
        }
      }
    }

    .show.dark,
    .hide.dark {
      color: white;

      &:hover {
        .arrow {
          color: white;
        }
      }
    }

    .arrow.dark {
      color: #d6d6db;

      &:hover {
        color: white;
      }
    }
  }
}