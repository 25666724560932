.custom-slider {
  margin-bottom: 20px !important;
  margin-top: 0;

  .ant-slider-mark {
    span:first-child {
      transform: translateX(0)!important;
    }
    span:last-child {
      transform: translateX(-90%)!important;
      width: 100px !important;
    }
  }
}

.custom-slider.light {
  .ant-slider-rail {
    background-color: #f0eff9 !important;
  }
  .ant-slider-track {
    background-color: #bda7e8 !important;
  }

  .ant-slider-step {
    .ant-slider-dot {
      border-color: #9589ad !important;
    }
  }

  .ant-slider-mark {
    span {
      color: rgba(64, 42, 135, 1);
    }
  }
}

.custom-slider.light {
  .ant-slider:hover {
    .ant-slider-track {
      background: #bda7e8 !important;
    }
  }
  .ant-slider-track {
    background: #bda7e8 !important;
  }
  .ant-slider-track:hover {
    background: #bda7e8 !important;
  }
}

.custom-slider.dark {
  .ant-slider:hover {
    .ant-slider-track {
        background: #402a87 !important;
    }
  }
  .ant-slider-track {
    background-color: #bda7e8 !important;
  }
  .ant-slider-track:hover {
    background-color: #bda7e8 !important;
  }

  .ant-slider-rail {
    background-color: rgba(189, 167, 232, 0.06) !important;
  }

  .ant-slider-step {
    .ant-slider-dot {
      border-color: #fff;
      background-color: #4a495c;
    }
  }

  .ant-slider-handle {
    &:after {
      box-shadow: 0 0 0 2px #fff !important;
      background-color: #4a495c;
    }
  }

  .ant-slider-mark {
    span {
        color: #fff;
    }
  }
}

.custom-slider.dark.ant-slider-disabled {
  .ant-slider-track {
    background-color: #bda7e8 !important;
  }

  .ant-slider-step {
    .ant-slider-dot {
      border-color: #fff;
      background-color: #4a495c;
    }
  }

  .ant-slider-handle {
    &:after {
      box-shadow: 0 0 0 2px #fff !important;
      background-color: #4a495c;
    }
  }

  .ant-slider-mark {
    span {
      color: #d6d6db;
    }
  }

  .ant-slider-mark-text-active {
    color: #fff !important;
  }
}

