@form-input-box-shadow-light: 0 0 10px rgba(64, 42, 135, 0.4);
@form-input-bg-on-focus-light: rgba(255, 255, 255, 0.6);
@form-input-color-on-focus: #402a87;

@form-input-box-shadow-dark: 0 0 8px rgba(189, 167, 232, 0.4);
@form-input-bg-on-focus-dark: rgba(255, 255, 255, 0.8);

@form-label-text-regular: 500;
@form-label-text-bold: 700;

@form-input-border-on-disabled-light: rgba(0, 0, 0, 0.2);
@form-input-border-on-disabled-dark: rgba(255, 255, 255, 0.4);

@form-input-bg-on-disabled-light: rgba(255, 255, 255, 0.4);
@form-input-bg-on-disabled-dark: rgba(255, 255, 255, 0.1);

@form-input-color-on-disabled-light: rgba(255, 255, 255, 0.2);
@form-input-color-on-disabled-dark: rgba(255, 255, 255, 0.2);

@form-dropdown-active-color: rgba(189, 167, 232, 0.6);
@form-dropdown-item-content-color: rgba(189, 167, 232, 1);
@form-dropdown-dark-bg-color: rgba(74, 73, 92, 1);

@form-dropdown-dark-bg-color: rgba(74, 73, 92, 1);

@media screen and (min-width: 769px) {
  .ant-modal {
    .ant-form {
      width: fit-content;
      min-width: min-content;
      max-width: 100%;

      div.ant-col.col-form-item-component,
      div.ant-col.col-form-item-component-view {
        width: 100% !important;
        max-width: 100%;
      }
    }
    .ant-col:not(.form_item_draft):not(.members_select):not(
        .setting_configuration
      ):not(.setting_worker) {
      .ant-form-item-row {
        display: flex;
        min-width: max-content;
        width: 100%;
        max-width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        // justify-content: right;
        align-items: center;
        gap: 24px;

        .ant-form-item-control {
          width: 320px !important;
          min-width: 320px !important;
          max-width: 320px !important;
          align-items: flex-start;
        }
      }
    }
    .form_item_draft {
      .ant-form-item-row {
        width: 100%;

        display: grid;
        grid-template-columns: 400px auto;
        align-items: center;
        justify-content: space-between;

        .ant-col.ant-col-6.ant-form-item-label.ant-form-item-label-left.ant-form-item-label-wrap {
          width: 100% !important;
          max-width: unset;
        }

        label.ant-form-item-no-colon {
          white-space: normal;
        }

        .ant-form-item-control-input-content {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .ant-checkbox-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .ant-checkbox-inner {
          border-radius: 4px !important;
        }
      }
    }
    &:not(.clock-in-out-modal) {
      .btns_row .ant-form-item-control,
      .ant-form-item {
        width: auto !important;
        min-width: auto !important;
        max-width: 100% !important;
      }
    }

    .ant-form-item-label {
      max-width: max-content !important;
      white-space: nowrap !important;
      align-self: flex-start;
    }
  }
}

@media screen and (max-width: 768px) {
  .form_item_draft {
    .ant-row.ant-form-item-row {
      display: grid;
      grid-template-columns: 90% 1fr;
      align-items: center;
      justify-content: space-between;

      .ant-col.ant-col-6.ant-form-item-label.ant-form-item-label-left.ant-form-item-label-wrap {
        width: 100% !important;
        max-width: unset;
      }
    }

    .ant-checkbox-inner {
      border-radius: 4px !important;
    }

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .ant-checkbox-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.day_edit_form {
  scrollbar-width: none;
  .two-inpunts-on-row {
    .ant-form-item-explain-error {
      max-width: 150px;
    }
  }
}

.ant-form-item-control-input {
  width: 100%;
}

.ant-form-item .ant-form-item-label > label::after {
  display: none;
}

.col-form-item-component {
  font-weight: 500 !important;
  font-size: 12px;
  margin: 0 !important;

  span {
    font-weight: 500 !important;
  }
}

#hardwareForm,
#employeeForm,
#assetCategoryForm,
#AssetSoftwareForm,
#teamForm {
  .ant-form-item label:not(.ant-form-item-required) {
    margin-left: 8px;
  }
}

.col-form-item-component-view {
  .employee-item {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.col-form-item-component-view.dark {
  .ant-select {
    font-weight: 500;

    border-radius: 10px;
  }

  .ant-select-selection-item,
  input,
  textarea,
  .ant-picker .ant-picker-input > input[disabled] {
    font-weight: 500;
    cursor: default;
    color: #fff;
  }
}

.col-form-item-component-view.light {
  .ant-select {
    span,
    input {
      font-weight: 500 !important;
    }

    border-radius: 10px;

    .ant-select-selector {
      background: @form-input-bg-on-disabled-light;
    }
  }

  .ant-select-selection-item,
  input,
  textarea,
  .ant-picker .ant-picker-input > input[disabled] {
    opacity: 1 !important;
    font-weight: 500;
    cursor: default;
    -webkit-text-fill-color: @form-input-color-on-focus;
    color: @form-input-color-on-focus !important;
  }
}

.col-form-item-component.light .ant-col.ant-form-item-control,
.col-form-item-component.light .ant-form-item-row {
  .set-form-item-row-component(@form-input-color-on-focus, @form-input-box-shadow-light, @form-label-text-bold, @form-input-bg-on-focus-light, rgba(255, 255, 255, 0.4), @form-input-border-on-disabled-light);
  .set-picker-and-select-on-disabled(@form-input-color-on-disabled-light, @form-input-border-on-disabled-light, @form-input-bg-on-disabled-light);
  .set-arrow-color-state-change(@form-dropdown-item-content-color);
}

.col-form-item-component.dark.disabled {
  .ant-select-selection-item,
  input,
  label {
    color: @form-input-color-on-disabled-dark !important;
  }

  .ant-select,
  .ant-picker.ant-picker-disabled,
  textarea {
    font-weight: 500;
    border-radius: 10px;
    background: rgba(189, 167, 232, 0.04);
  }

  .ant-col.ant-form-item-control
    .ant-select.ant-select-disabled
    .ant-select-selector,
  .ant-form-item-row .ant-select.ant-select-disabled .ant-select-selector {
    background: none;
  }
}

.col-form-item-component.light.disabled {
  .ant-picker.ant-picker-disabled input {
    color: @form-input-border-on-disabled-light;
  }
}

.col-form-item-component.dark,
.col-form-item-component-view.dark .ant-col.ant-form-item-control,
.col-form-item-component.dark .ant-form-item-row {
  .set-form-item-row-component(#fff, @form-input-box-shadow-dark, @form-label-text-bold, @form-input-bg-on-focus-dark, rgba(255, 255, 255, 0.1), @form-input-border-on-disabled-dark);
  .set-picker-and-select-on-disabled(@form-input-color-on-disabled-dark, @form-input-border-on-disabled-dark, @form-input-bg-on-disabled-dark);
  .set-arrow-color-state-change(@form-dropdown-item-content-color);
}

.col-form-item-component.light.disabled .ant-col.ant-form-item-control,
.col-form-item-component.light.disabled .ant-form-item-row {
  .set-form-label(rgba(0, 0, 0, 0.2), @form-label-text-regular);
}

.col-form-item-component.dark.disabled .ant-col.ant-form-item-control,
.col-form-item-component.dark.disabled .ant-form-item-row {
  .set-form-label(rgba(255, 255, 255, 0.4), @form-label-text-regular);
}

.ant-input {
  font-weight: 500;
  font-size: 12px;
}

.ant-form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 16px;
}

.ant-form-item {
  margin: 0px;
}

.ant-form-item-label {
  font-weight: 500 !important;
  padding: 0px !important;

  label {
    margin: 0px;
  }
}

.set-ant-select-in-form-item(@color) {
  .ant-select-selector,
  .ant-picker,
  .ant-input {
    border-color: @color !important;
    border-inline-end-width: 1px !important;
  }
}

.set-dropdown(@form-input-color-on-focus);

.set-form-item-row-component(@hoverLabelColor, @boxShadowColor, @labelFontWeight, @inputBackgroundOnFocus, @inputBackgroundOnDisabled, @inputColorDisabled) {
  &:active,
  &:focus-within,
  &:focus-visible,
  &:hover {
    .set-form-label(@hoverLabelColor, @labelFontWeight);
    .set-ant-select-in-form-item(@hoverLabelColor);
    .set-arrow-color-state-change(@hoverLabelColor);
  }

  label {
    font-size: 12px;
    font-weight: 500;
  }

  input,
  textarea {
    text-overflow: ellipsis;

    &:focus:enabled,
    &:active:enabled {
      background: @inputBackgroundOnFocus;
      color: @form-input-color-on-focus;

      box-shadow: @boxShadowColor;
    }

    &:disabled {
      cursor: unset;
      color: @inputColorDisabled;
      border-color: @inputColorDisabled;
    }
  }

  .ant-select:not(.ant-select-disabled),
  .ant-picker:not(.ant-picker-disabled) {
    .set-picker-and-select(@inputBackgroundOnFocus, @boxShadowColor, @hoverLabelColor, @form-input-color-on-focus);
  }

  .ant-picker:not(.ant-picker-disabled) {
    &:focus-within,
    &:active {
      background: @inputBackgroundOnFocus;
      box-shadow: @boxShadowColor !important;

      .ant-select-selection-item,
      .ant-picker-clear {
        color: @form-input-color-on-focus;
      }
    }
  }
}

.set-picker-and-select-on-disabled(@color, @borderColor, @backgroundColor) {
  .ant-select.ant-select-disabled .ant-select-selector,
  .ant-picker.ant-picker-disabled {
    border-color: @borderColor;
    background: @backgroundColor;
  }
}

.set-picker-and-select(@inputBackgroundOnFocus, @boxShadowColor, @hoverLabelColor, @formInputColor) {
  &:focus {
    // box-shadow: none;
    // box-shadow: @boxShadowColor !important;
    box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4) !important;
  }

  &:focus-within,
  &:active {
    .ant-select-clear,
    .ant-select-arrow {
      color: @formInputColor;
    }
  }

  &.ant-select-open .ant-select-arrow {
    transform: scaleY(-1);
  }

  .ant-select-selector,
  .ant-picker {
    font-weight: 500;

    &:focus-within,
    &:active {
      background: rgba(255, 255, 255, 1);
      // box-shadow: @boxShadowColor !important;
      box-shadow: none !important;

      .ant-select-selection-item,
      .ant-picker-clear {
        color: @formInputColor;
      }
    }
  }

  .ant-select-clear,
  .ant-picker-clear {
    color: @hoverLabelColor;
  }

  .ant-picker-input input,
  .ant-select-selection-search input {
    &:focus:enabled,
    &:active:enabled {
      background: none;
      box-shadow: none;
    }
  }

  .ant-picker-clear,
  .ant-select-clear {
    background: none;
    box-shadow: none;
  }
}

.ant-picker-dropdown-range {
  padding: 0 !important;
}

.light,
:has(.light) {
  .ant-picker-dropdown,
  .ant-picker-panel-container,
  .ant-picker-panel-layout {
    background: #fcfcfe !important;
  }
}

.dark,
:has(.dark) {
  .ant-picker-dropdown,
  .ant-picker-panel-container,
  .ant-picker-panel-layout {
    background: #4a495c !important;
  }

  .ant-select-focused {
    span {
      color: #402a87 !important;
    }
  }

  .ant-picker-focused {
    input {
      color: #402a87 !important;
    }
  }
}

.ant-picker-dropdown:has(.ant-picker-time-range-wrapper) {
  background: none !important;
}

.set-dropdown(@color) {
  .dark {
    .ant-select-selection-item,
    .ant-picker-input input {
      color: #fff !important;
      border: none !important;
    }

    .ant-select-item.ant-select-item-option-selected,
    .ant-select-item-option-active {
      background: @form-dropdown-active-color;
    }

    .ant-select-dropdown
      .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(
        .ant-select-item-option-selected
      ) {
      background: none;

      &:hover {
        background: @form-dropdown-active-color;
      }
    }

    .ant-select-dropdown,
    .ant-picker-dropdown,
    .ant-picker-panel-container {
      .ant-select-item-option-selected {
        .set-dropdown-item-option-content(#fff);
      }

      .ant-select-item:hover {
        .set-dropdown-item-option-content(#fff);
      }

      .set-dropdown-item-option-content(@form-dropdown-item-content-color);
      background: @form-dropdown-dark-bg-color;
    }

    .ant-picker-dropdown .ant-picker-today-btn {
      color: @form-dropdown-item-content-color;

      &:hover {
        color: @form-dropdown-item-content-color;
        opacity: 0.7;
      }
    }
  }

  .ant-select-dropdown,
  .ant-picker-dropdown {
    border-radius: 16px;
  }
  .ant-pagination {
    .ant-select-dropdown,
    .ant-picker-dropdown {
      border-radius: 10px !important;
      left: 0 !important;
    }
  }

  .ant-select-selection-item,
  .ant-picker-input input {
    font: inherit;
    font-weight: 500;
    color: #402a87 !important;
  }

  .ant-select-dropdown {
    .ant-select-item,
    .ant-select-item.ant-select-item-option-selected {
      color: @color;
    }

    .ant-select-item-option-state {
      color: @color !important;
    }
  }

  .time-range-picker {
    border: none !important;
    box-shadow: none !important;
    .ant-picker-panel-layout {
      border-radius: 15px;
    }
    &.dark {
      .ant-picker-panel-layout {
        border: 1px solid #fff;
        box-shadow: 0px 0px 16px 0px rgba(189, 167, 232, 0.4);
      }
    }
    &.light {
      .ant-picker-panel-layout {
        border: 1px solid #978bbe;
        box-shadow: 0px 0px 16px 0px rgba(189, 167, 232, 0.4);
      }
    }
  }
  .ant-picker-dropdown {
    border: 1px solid #fff;
    box-shadow: 0px 0px 16px 0px rgba(189, 167, 232, 0.4);

    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start,
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        background: @color;
      }
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background: @color !important;
    }

    .ant-picker-today-btn {
      color: rgba(189, 167, 232, 1);

      &:hover {
        color: rgba(189, 167, 232, 1);
        opacity: 0.7;
      }
    }
  }
}

.set-form-label(@labelColor, @labelFontWeight) {
  label {
    color: @labelColor;
    // font-weight: @labelFontWeight;
  }
}

.set-dropdown-item-option-content(@color) {
  .ant-select-item-option-content {
    color: @color;
  }
}

.set-arrow-color-state-change(@color) {
  .ant-select-arrow {
    color: @color;
  }
}

@media (max-width: 769px) {
  .ant-form .ant-form-item {
    .ant-form-item-control {
      flex: 1 1 0;
    }

    .ant-form-item-label {
      flex: 0 0 100%;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .ant-modal {
    .ant-col:not(.form_item_draft):not(.members_select):not(
        .setting_configuration
      ):not(.setting_worker) {
      .ant-form-item-row {
        gap: 24px;
      }
    }
  }
  .ant-row.ant-form-item-row .ant-col.ant-col-6 {
    display: flex;
  }
}