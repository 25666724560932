.custom-radio {
  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    span {
      color: rgba(64, 42, 135, 0.45);
    }
  }

  .ant-radio {
    width: 16px;
    height: 16px;

    .ant-radio-inner {
      width: 16px;
      height: 16px;
    }
  }

  .ant-radio-wrapper-checked{
    span {
      font-weight: 500 !important;
    }
  }
}

.custom-radio.light {
  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    span {
      color: #402a87;
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-in-form-item {
    :where(.ant-radio-wrapper .ant-radio-inner) {
      border-color: rgba(64, 42, 135, 0.6) !important;
      background-color: #fff;
    }

    :where(.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner) {
      border-color: #402a87 !important;
      background-color: #fff !important;
      &:after {
        background-color: #402a87;
        transform: scale(0.42);
      }
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    .ant-radio {
      .ant-radio-inner {
        border-color: rgba(64, 42, 135, 0.6);
        &:after {
          background-color: #402a87;
        }
      }
    }
  }
}

.ant-radio-wrapper.ant-radio-wrapper-disabled.ant-radio-wrapper-checked {
  span {
    color: rgba(64, 42, 135, 1);
  }
}

.custom-radio.dark {
  .ant-radio-inner::after {
    background-color: #402a87;
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    span {
      color: #d6d6db;
    }
  }

  :where(.ant-radio-wrapper .ant-radio-inner) {
    border-color: #fff !important;
    background-color: transparent;
  }

  .ant-radio-wrapper.ant-radio-wrapper-in-form-item {
    :where(.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner) {
      background-color: transparent !important;
      &:after {
        background-color: #bda7e8;
        transform: scale(0.42);
      }
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    .ant-radio {
      .ant-radio-inner {
        &:after {
          background-color: #bda7e8;
        }
      }
    }
  }
  .ant-radio-wrapper.ant-radio-wrapper-disabled.ant-radio-wrapper-checked{
    span {
      color: #fff !important;
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled {
    span {
      color: #d6d6db;
    }
  }

  .ant-radio-wrapper {
    span {
      color: #fff;
    }
  }
}
