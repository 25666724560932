.colleagues-reviews-general-data {
  display: flex;
  flex-direction: column !important;
  justify-content: unset !important;
  gap: 0;

  .typography-title-body {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    width: 160px;
  }

  .ant-select {
    display: flex;
    width: 400px !important;
  }

  .ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 45px;
    max-height: none;
    height: auto !important;
    align-items: center;

    .ant-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-height: none;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      height: auto !important;
      white-space: nowrap;
    }
    
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-overflow {
      max-height: 120px !important;
      overflow: hidden auto;
    }

    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          display: flex;
          height: 100% !important;
        }
      }
    }
  }

  .dropdown-options {
    display: flex;
    align-items: center;
    gap: 0.5em;
    min-height: 45px;
    height: auto;
  }
}

.colleagues-reviews-general-data.dark {
  .ant-picker.ant-picker-focused {
    background-color: #d6d6db;
    box-shadow: none !important;

    .ant-picker-clear {
      color: #402a87 !important;
      background: none !important;
    }
  }

  .ant-picker-clear {
    color: #d6d6db !important;
    background: none !important;
  }
}

