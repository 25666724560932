.select-options {
  .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select {
    .ant-select-arrow,
    .ant-select-clear {
      color: #bda7e8 !important;
      background: none !important;
    }

    .ant-select-clear {
      .anticon-close-circle {
        color: #402a87;
      }
    }
  }

  .ant-select.dark {
    .ant-select-selector {
      color: #402a87 !important;
    }
  }

  .ant-select.ant-select-open {
    background-color: #d6d6db;
    border-radius: 12px;

    .ant-typography {
      color: #402a87;
    }
  }

  .employee-select.dark.ant-select-disabled {
    .ant-select-selector {
      background-color: rgba(74, 73, 92, 0.5) !important;
    }
  }

  .ant-select-selector.ant-select-focused {
    box-shadow: none !important;
  }

  .ant-select-dropdown {
    border: 1px solid #d6d6db !important;
  }

  .ant-select-dropdown.employee-select.employee-select-popup {
    transition: none !important;
    z-index: -1;
    overflow: visible !important;
    font-weight: 500 !important;
    border: 1px solid #d6d6db;

    &.dark {
      border: 1px solid #d6d6db !important;
      background: #4a495c !important;

      &::before {
        background: none !important;
      }
    }

    &.light {
      border: 1px solid #978bbe;
      background: #fff !important;
    }

    .ant-select-item-option-active {
      font-weight: 700;
    }

    .ant-select-item {
      font-weight: 500;
      border-radius: 5px;
      padding: 8.5px 11px;

      .ant-typography {
        font-weight: inherit !important;
      }

      &:hover {
        font-weight: 700;
      }
    }

    &::before {
      z-index: 10;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 0px;
      background: none;
      mask: none;
      mask-composite: exclude;
      pointer-events: none;
    }
  }

  .form-dropdown {
    top: 400px !important;
  }
}

.employee-select-popup.dark {
  border: 1px solid #d6d6db !important;
}

.dropdown-options {
  display: flex;
  gap: 0.5em;
  white-space: normal;
  word-break: break-word;
  max-width: 100%;
}

.typography-title-body {
  white-space: normal;
  word-wrap: break-word;
}