.widget-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px !important;

  .widget-overview-content {
    max-width: 170px;
    .widget-overview-body {
      font-weight: 700;
      font-size: 1rem;
      margin: 0;
      &.active {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .ant-card-body {
    padding: 0 !important;
  }
}