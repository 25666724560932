.colleagues-reviews {
  background: none !important;
  backdrop-filter: none !important;
  border-radius: 20px;
  box-shadow: none !important;

  .colleagues-reviews-tabs {
    display: flex;
    gap: 1em;
  }

  .btn-default-custom {
    border: 0;
    font-size: 1rem;
    box-shadow: none;
    display: flex;
    align-items: center;
  }

  .btn-default-custom.active.light {
    border: 1px solid #402a87;
  }

    .btn-default-custom.active.dark {
      border: 1px solid #d6d6db;
    }

  .ant-tabs-tab {
    font-size: 0.875rem;
  }
}

.colleagues-reviews-table.dark {
  ul {
    a {
      color: #bda7e8;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 180px;
}