.personal-reviews {
  background: none !important;
  backdrop-filter: none !important;
  border-radius: 20px;
  box-shadow: none !important;
}

.personal-reviews.dark  {
  ul {
    a {
      color: #bda7e8;
    }
  }
}