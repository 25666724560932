.colleagues-review-goals {
  display: flex;
  flex-direction: column !important;
  justify-content: unset !important;
  gap: 1em;

  .colleagues-review-goals-form {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    width: 100%;

    .input-textarea.light {
      color: #402a87;
    }

    .input-textarea.dark {
      color: #fff;
    }

    textarea {
      &::-webkit-scrollbar-track {
        margin: 0.5em 0;
      }
    }
  }

  .typography-title-body {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    width: 100%;
  }

  .ant-form-item-explain-error {
    transform: translateY(-90%);
  }
}