.colleagues-review-summary {
  display: flex;
  flex-direction: column;
  width: 30% !important;
  backdrop-filter: blur(15px) !important;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
  padding: 16px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(#ffffff, #ffffff66) !important;
  }

  .colleagues-review-summary-sections{
    display: flex;
    flex-direction: column;
    gap: 1em;

    .review-name {
      display: flex;
      gap: 1em;
      .name-text {
        font-weight: 700;
        background: rgba(189, 167, 232, 1);
        padding: 0 8px;
        border-radius: 10px;
      }
    }

    .review-type {
      display: flex;
      gap: 1em;
      .type-text {
        font-weight: 700;
        color: rgba(33, 150, 83, 1);
      }
    }
  }

  .ant-typography {
    line-height: 1.66;
  }
}