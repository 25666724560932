.widget-card {
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 70% !important;
  backdrop-filter: blur(15px) !important;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
  padding: 16px;
  flex-flow: nowrap;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(#ffffff, #ffffff66) !important;
  }
}

.ant-card.card-widget .ant-card-cover {
  height: 100% !important;
}

.view-review-content {
  display: flex !important;
  flex-direction: column;
  gap: 12px;
  padding: 3em 3em;
  height: 100%;

  .view-review-body {
    display: flex;
    gap: 1em;
    height: 100%;
  }
}

.view-feedback-review-template {
  .review-template {
    overflow: hidden auto;
    height: 100%;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .typography-title-body {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .typography-paragraph {
    font-size: 0.875rem;
  }

  .view-review-input:focus {
    box-shadow: none !important;
  }

  .view-review-input.dark:focus {
    background: #f0eff9;
    color: #402a87;
  }

  .view-review-input.light.ant-input-disabled {
    color: rgba(64, 42, 135, 0.88) !important;
  }

  .view-review-input.dark.ant-input-disabled {
    color: #fff !important;
  }

  .view-review-template-button {
    display: flex;
    justify-content: center;
    margin-top: 21px;
  }

  .edit-btn,
  .remove-btn{
    display: flex;
    align-items: center;
    padding: 11px;
  }

  .arrow-btn {
    background: none;
    border: none;
    display: flex;
    box-shadow: none;
    align-items: center;
    padding: 0;
  }

  .template-change {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    .employee-name {
      min-width: 205px;
      text-align: center;
    }
  }

  .splide__arrow {
    color: rgba(64, 42, 135, 0.45);
    cursor: pointer;

    &:is(:hover, :active, :focus) {
      color: #402a87;
    }

    &.dark {
        color: #bda7e8;
    }

    &.dark:hover {
      color: white;
    }

    &.disabled {
      color: rgba(64, 42, 135, 0.40);
    }
  }

  .splide__arrow.dark.disabled {
    color: #bda7e8;
  }
}

.empty-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
