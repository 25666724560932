.custom-checkbox {
  .ant-checkbox-wrapper {
    display: flex !important;
    align-items: center;
  }
  .ant-checkbox-wrapper-checked {
    span {
      font-weight: 500;
    }
  }
}

.custom-checkbox.light {
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
    span {
      color: #402a87;
    }
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: rgba(64, 42, 135, 0.6) !important;
      }
    }

    span {
      color: rgba(64, 42, 135, 0.45);
    }
  }

  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: rgba(64, 42, 135, 0.6) !important;
      }
    }
  }
}

.custom-checkbox.dark {
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background-color: #bda7e8 !important;
      border-color: #fff;
      &:after {
        border-color: #fff !important;
      }
    }
  }

  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: #fff !important;
      }
    }

    .ant-checkbox.ant-checkbox-checked {
      &:after {
        border: none !important;
      }
    }
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
    span {
      color: #fff;
    }

    .ant-checkbox-inner {
      background-color: #bda7e8 !important;
      &:after {
        border-color: #fff !important;
      }
    }
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: #fff !important;
      }
    }
    span {
      color: #d6d6db;
    }
  }

  .ant-checkbox-wrapper {
    span {
      color: #fff;
    }
  }
}