.select-status{
  .dd-wrapper {
    height: 20px;

    .dd-header {
      font-weight: 700;
      background: #bda7e8;
      padding: 0 8px;
      border-radius: 10px;
      height: 20px;
    }
  }

  .dd-wrapper:after {
    display: none;
  }
}

.select-status.light {
  .dd-header__arrow {
    color: #402a87;
  }
}

.select-status.dark {
  .dd-header__arrow {
    color: #ffffff;
  }
}

.modal-select-status {
  .ant-modal-content {
    .ant-modal-header {
      margin-bottom: 1rem;
    }
    .ant-modal-body {
      font-weight: 500 !important;
      margin-bottom: 1rem;
    }
    .ant-modal-footer {
      margin: 0;
    }
  }
}
