.widget-card {
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 70% !important;
  backdrop-filter: blur(15px) !important;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
  padding: 16px;
  flex-flow: nowrap;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(#ffffff, #ffffff66) !important;
  }
}

.ant-card.card-widget .ant-card-cover {
  height: 100% !important;
}

.colleagues-reviews-content {
  display: flex !important;
  flex-direction: column;
  gap: 12px;
  padding: 3em 3em;
  height: 100%;

  .colleagues-review-body {
    display: flex;
    gap: 1em;
    height: 100%;
  }
}

.review-filters {
  margin-left: 14px;
  display: flex;
  width: 100%;
}