.questions-feedback {
  .notes {
    background-color: rgba(189, 167, 232, 0.6) !important;
    padding: 0.5rem;
    border-radius: 14px;

    .show,
    .hide {
      align-items: center;
      font-size: 0.875rem;
      cursor: pointer;
      .arrow {
        color: #bda7e8;
      }

      &:hover {
        text-decoration: underline;

        .arrow {
          color: #402a87;
        }
      }
    }

    .show.dark,
    .hide.dark {
      color: white;

      &:hover {
        .arrow {
          color: white;
        }
      }
    }

    .arrow.dark {
      color: #d6d6db;

      &:hover {
        color: white;
      }
    }
  }
}