.view-review-tab-bar {
  display: flex;
  gap: 10px;

  .btn-default-custom {
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    padding-left: 31px;
    padding-right: 31px;
    backdrop-filter: blur(15px) !important;

    &.active {
      font-weight: 700 !important;
    }
  }
}