@confirm-modal-component-light-bg: rgba(255, 255, 255, 1);
@confirm-modal-component-light-box-shadow: 0 0 40 rgba(64, 42, 135, 0.4);

@confirm-modal-component-dark-bg: #4a495c;
@confirm-modal-component-dark-box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);

@box-shadow-dark: 0px 0px 10px rgba(189, 167, 232, 0.4);

@disabled-dark: rgba(189, 167, 232, 0.2);
@disabled-light: rgba(189, 167, 232, 0.4);
@default-color-dark: #BDA7E8;
@default-color-light: rgba(64, 42, 135, 0.88);

@color-primary-light: #402a87;
@color-bg-default-light: rgba(64, 42, 135, 0.2);
@box-shadow-light: 0px 0px 10px rgba(64, 42, 135, 0.4);

@media screen and (max-width: 768px) {
  .ant-picker-ranges {
    padding: 2px 4px !important;
  }

  .ant-picker-ok {
    margin: 0 !important;
    width: 100% !important;

    & button {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  .ant-picker-content {
    height: 110px !important;

    .ant-picker-time-panel-column {
      overflow-y: auto;
    }
  }

  .custom-time-range-picker-modal {
    transition: 5s ease-in;

    .ant-modal-body {
      height: 400px !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow-y: hidden !important;
    }
  }

  .MuiClock-clock {
    padding: 0.9rem !important;
  }
}

#custom-ant-timerange-picker-wrapper {
  .ant-picker-active-bar {
    display: none !important;
  }
}

.MuiPickersToolbar-root {
  display: flex !important;
  align-items: center !important;
}

.MuiPickersToolbar-content {
  display: flex !important;
  justify-content: center !important;
}

.MuiStack-root {
  padding: 0 !important;
}

.css-13cnpq9-MuiPickersLayout-root {
  background-color: transparent !important;
}

.css-9ct1zb {
    background-color: transparent !important;
}

.MuiPickersToolbar-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-time-range-picker-modal {
  width: 475px !important;
  height: 565px !important;
  border-radius: 20px !important;

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 !important;
    height: 551px !important;
    border-radius: 20px !important;
  }

  .ant-modal-footer {
    padding: 0 24px 0 24px;
  }
}

.MuiTypography-root.MuiTypography-overline {
  display: none;
}

.MuiClock-clock {
  padding: 1.5rem;
}

.custom-time-range-picker-modal.light {
  .ant-modal-content {
    background: @confirm-modal-component-light-bg;
    box-shadow: @confirm-modal-component-light-box-shadow;
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  .ant-modal-footer {
    .ant-btn:first-child {
      color: @color-primary-light;
      border-color: @color-primary-light;
      background: transparent !important;

      &:hover {
        color: @color-primary-light !important;
        border-color: @color-primary-light;
        border: 1px solid rgba(var(--accent-red-base-btn-primary),
        var(--accent-green-base-btn-primary),
        var(--accent-blue-base-btn-primary),
        1);
        background-color: @color-bg-default-light;
        box-shadow: @box-shadow-light;
      }
    }

    .ant-btn:last-child {
      background: linear-gradient(97deg, var(--myColor1), var(--myColor2));
      transition: --myColor1 0.1s, --myColor2 0.1s;

      border-color: transparent;
      box-shadow: none;
      border: 0;

      &:hover {
        --myColor1: @default-color-dark;
        --myColor2: #402a87;
        box-shadow: @box-shadow-dark;
      }
    }
  }

  .MuiPickersToolbar-root {
    span {
      color: @color-primary-light;
    }
  }

  .MuiPickersLayout-root.css-13cnpq9-MuiPickersLayout-root {
    background-color: @confirm-modal-component-light-bg;
  }

  .MuiPickersToolbar-content {
    .MuiTimePickerToolbar-hourMinuteLabel {
      .MuiButtonBase-root {
        .MuiTypography-root {
          padding: 0 2px;
        }
        .MuiTypography-root.Mui-selected {
          border: 1px solid @color-primary-light;
          border-radius: 14px;
        }
      }
    }
  }

  .MuiClock-clock {
    background-color: transparent;
    border: 1px solid @default-color-light;
  }

  .MuiPickersLayout-contentWrapper {
    .MuiTimeClock-root {

      .MuiClock-root {
        .MuiClock-pin,
        .MuiClockPointer-root,
        .MuiClockPointer-thumb {
          background-color: @color-primary-light;
        }

        .MuiClockPointer-thumb {
          border: 16px solid @color-primary-light;
        }


        .MuiClock-wrapper * > {
          color: @default-color-light;
          font-size: 1.2rem;
        }


        .Mui-selected {
          color: #ffffff;
        }

        .Mui-disabled {
          color: @disabled-light;
        }
      }

      .MuiPickersArrowSwitcher-root {
        top: 0;

        .MuiButtonBase-root {
          color: @color-primary-light;
          font-size: 25px;
        }

        .Mui-disabled {
          color: @disabled-light;
        }
      }
    }
  }
}

.custom-time-range-picker-modal.dark {
  .ant-modal-content {
    background: @confirm-modal-component-dark-bg;
    box-shadow: @confirm-modal-component-dark-box-shadow;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  .ant-modal-footer {
    .ant-btn:first-child {
      background-color: transparent;

      &:hover {
        color: #ffffff;
        border: 1px solid #ffffff;
        background-color: rgba(189, 167, 232, 0.2);
        box-shadow: 0px 0px 10px rgba(189, 167, 232, 0.4);
      }
    }

    .ant-btn:last-child {
      background: linear-gradient(97deg, var(--myColor1), var(--myColor2));
      transition: --myColor1 1s, --myColor2 1s;

      border-color: transparent;
      box-shadow: none;
      border: 0;

      &:hover {
        --myColor1: @default-color-dark;
        --myColor2: #402a87;
        box-shadow: @box-shadow-dark;
      }
    }
  }

  .MuiPickersToolbar-root {
    span {
      color: #ffffff;
    }
  }

  .MuiPickersLayout-root.css-13cnpq9-MuiPickersLayout-root {
    background-color: @confirm-modal-component-dark-bg !important;
  }

  .css-13cnpq9-MuiPickersLayout-root {
    background-color: transparent !important;
  }

  .MuiPickersLayout-root {
    .css-9ct1zb {
      background-color: transparent !important;
    }
  }

  .MuiPickersToolbar-content {
    .MuiTimePickerToolbar-hourMinuteLabel {
      .MuiButtonBase-root {
        .MuiTypography-root {
          padding: 0 2px;
        }
        .MuiTypography-root.Mui-selected {
          background: @confirm-modal-component-dark-bg;
          box-shadow: @confirm-modal-component-dark-box-shadow;
          border: 1px solid #ffffff;
          border-radius: 14px;
        }
      }
    }
  }

  .MuiClock-clock {
    background: @confirm-modal-component-dark-bg;
    box-shadow: @confirm-modal-component-dark-box-shadow;
    border: 1px solid #ffffff;
  }

  .MuiPickersLayout-contentWrapper {
    .MuiTimeClock-root {

      .MuiClock-root {
        .MuiClock-pin,
        .MuiClockPointer-root,
        .MuiClockPointer-thumb {
          background-color: @default-color-dark;
        }

        .MuiClockPointer-thumb {
          border: 16px solid @default-color-dark;
        }


        .MuiClock-wrapper * > {
          color: @default-color-dark;
          font-size: 1.2rem;
        }


        .Mui-selected {
          color: #ffffff;
        }

        .Mui-disabled {
          color: @disabled-dark;
        }
      }

      .MuiPickersArrowSwitcher-root {
        top: 0;

        .MuiButtonBase-root {
          color: @default-color-dark;
          font-size: 25px;
        }

        .Mui-disabled {
          color: @disabled-dark;
        }
      }
    }
  }
}