.colleagues-reviews-toolbar {
  padding: 10px 24px;
  display: flex;
  justify-content: end;

  .toolbar-buttons {
    padding: 0;
  }

  button {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.colleagues-reviews-toolbar.toolbar-filters {
  padding-left: 0;
}

.modal-warning {
  .ant-modal-content {
    .ant-modal-header {
      margin-bottom: 1rem;
    }
    .ant-modal-body {
      font-weight: 500 !important;
      margin-bottom: 1rem;
    }
    .ant-modal-footer {
      margin: 0;
    }
  }
}
