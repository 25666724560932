.view-review-template {
  .review-template {
    overflow: hidden auto;
    height: 100%;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .slider-form-item {
      .ant-form-item-control {
        margin-bottom: 5px;
      }

      .ant-form-item-explain-error {
        margin-top: 8px;
      }
    }
  }

  .typography-title-body {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .typography-paragraph {
    font-size: 0.875rem;
  }

  .view-review-input {
    .ant-form-item-explain-error {
        margin-top: 10px;
    }
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .view-review-input:focus {
    box-shadow: none !important;
  }

  .view-review-input.dark:focus {
    background: #f0eff9;
    color: #402a87;
  }

  .view-review-input.light.ant-input-disabled {
    color: rgba(64, 42, 135, 0.88) !important;
  }

  .view-review-input.dark.ant-input-disabled {
    color: #fff !important;
  }

  .view-review-template-button {
    display: inline-flex;
    justify-content: center;
    margin-top: 21px;
  }

  .view-review-checkbox {
    label {
      display: flex;
      align-items: center;
    }
  }

  .edit-btn,
  .remove-btn{
    display: flex;
    align-items: center;
    padding: 11px;
  }

  .arrow-btn {
    background: none;
    border: none;
    display: flex;
    box-shadow: none;
    align-items: center;
    padding: 0;
  }

  .template-change {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1em;
    gap: 0.5em;
  }

  .splide__arrow {
    color: #bda7e8;
    cursor: pointer;

    &:is(:hover, :active, :focus) {
      color: #402a87;
    }

    &.dark {
      color: rgba(255, 255, 255, 0.86) !important;
    }

    &.dark:hover {
      color: white !important;
    }

    &.disabled {
      color: rgba(64, 42, 135, 0.45);
      cursor: not-allowed;
    }
  }

  .splide__arrow.dark.disabled {
    color: rgba(255, 255, 255, 0.70) !important;
  }
}