.colleagues-reviews-general-settings {
  display: flex;
  flex-direction: column !important;
  justify-content: unset !important;
  gap: 1em;
  .typography-title-body {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    width: 100%;
  }
}